import { Canvas } from "@react-three/fiber";
import { FC } from "react";
import Galaxy from "./Galaxy";

const GalaxyBackground: FC = () => {
  return (
    <div className="absolute inset-0 z-10">
      <Canvas
        camera={{
          position: [0, 2, 5],
          fov: 75,
          near: 0.1,
          far: 100,
        }}
      >
        <Galaxy />
      </Canvas>
    </div>
  );
};

export default GalaxyBackground;
