import { FC } from "react";
import { StatsGrid } from "./StatsGrid";
import { StatsContainer } from "./StatsContainer";
import { HaloEffects } from "./HaloEffects";

const Stats: FC = () => {
  return (
    <section
      id="stats"
      className="relative w-full bg-gradient-to-b from-[#0A0A0B] via-[#0A0A0B] to-[#0A0A0B] text-indigo-50"
    >
      <StatsContainer>
        <HaloEffects />
        <StatsGrid />
      </StatsContainer>
    </section>
  );
};

export default Stats;
