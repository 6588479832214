import { FC, PropsWithChildren } from "react";
import { motion } from "framer-motion";

export const StatsContainer: FC<PropsWithChildren> = ({ children }) => {
  return (
    <motion.div
      initial={{ y: -20, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="relative w-full bg-indigo-900/20 backdrop-blur-xl border-y border-indigo-500/30 overflow-hidden"
    >
      {children}
    </motion.div>
  );
};
