import React from "react";
import Hero from "../components/Hero";
import Stats from "../components/Stats/Stats";
import MainContent from "../components/MainContent/MainContent";

const Home: React.FC = () => {
  return (
    <>
      <Hero />
      <Stats />
      <MainContent />
    </>
  );
};

export default Home;
