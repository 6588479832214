import { motion } from "framer-motion";
import { FC } from "react";
import { ExploreButton } from "./ExploreButton";
import { ContactButton } from "./ContactButton";

interface CTAButtonsProps {
  variants: any;
}

export const CTAButtons: FC<CTAButtonsProps> = ({ variants }) => {
  return (
    <motion.div
      custom={0.5}
      variants={variants}
      initial="hidden"
      animate="visible"
      className="mt-14 flex desktop:flex-row laptop:flex-row tablet:flex-col mobile:flex-col desktop:gap-6 laptop:gap-6 tablet:gap-4 mobile:gap-4"
    >
      <ExploreButton />
      <ContactButton />
    </motion.div>
  );
};
