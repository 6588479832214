import { FC } from "react";
import { SkillArea } from "./SkillArea";

export const SkillsSection: FC = () => (
  <div className="flex flex-col h-full min-h-[400px] gap-8">
    <h3 className="text-2xl font-semibold text-zinc-200">What I Provide</h3>
    <div className="grid desktop:grid-cols-1 laptop:grid-cols-1 tablet:grid-cols-1 mobile:grid-cols-1 gap-4 flex-grow">
      <SkillArea
        title="Full-Stack Development"
        icon="💻"
        description="Proficient in both frontend and backend technologies, with expertise in React, Node.js, and AWS cloud services"
      />
      <SkillArea
        title="UI/UX Focus"
        icon="🎨"
        description="Creating intuitive and engaging user interfaces with attention to detail"
      />
      <SkillArea
        title="Cloud Architecture"
        icon="☁️"
        description="Designing and implementing scalable solutions using AWS services and cloud-native architectures"
      />
    </div>
  </div>
);
