import { FC, useState } from "react";
import { motion } from "framer-motion";
import { FiCopy, FiCheck, FiMail } from "react-icons/fi";

const EmailButton: FC = () => {
  const [copied, setCopied] = useState(false);
  const email = "hugo.sinprasith@gmail.com";

  const handleCopyEmail = async () => {
    await navigator.clipboard.writeText(email);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <motion.button
      whileHover={{ scale: 1.02 }}
      whileTap={{ scale: 0.98 }}
      onClick={handleCopyEmail}
      className="group flex items-center justify-between bg-indigo-950/20 backdrop-blur-md border border-indigo-500/30 rounded-xl desktop:px-6 desktop:py-4 laptop:px-6 laptop:py-4 tablet:px-5 tablet:py-3.5 mobile:px-4 mobile:py-3 w-full transition-all duration-300 hover:border-indigo-400/50"
    >
      <div className="flex items-center desktop:gap-3 laptop:gap-3 tablet:gap-2 mobile:gap-2">
        <FiMail className="text-indigo-400 group-hover:desktop:text-indigo-300 laptop:text-indigo-300 tablet:text-indigo-300 mobile:text-indigo-200 transition-colors" />
        <span className="font-mono desktop:text-base laptop:text-base tablet:text-sm mobile:text-xs text-indigo-300 group-hover:text-indigo-200 transition-colors">
          {email}
        </span>
      </div>
      {copied ? (
        <FiCheck className="text-emerald-500" />
      ) : (
        <FiCopy className="text-indigo-400 group-hover:text-indigo-300 transition-colors" />
      )}
    </motion.button>
  );
};

export default EmailButton;
