import { FC } from "react";
import { motion } from "framer-motion";

const PersonalGradientBackground: FC = () => {
  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 2.5 }}
        className="absolute inset-0"
      >
        <div className="absolute desktop:bottom-[-65%] laptop:bottom-[-65%] tablet:bottom-[-45%] mobile:bottom-[-45%] desktop:right-[-25%] laptop:right-[-25%] tablet:right-[-35%] mobile:right-[-35%] w-[1000px] h-[1000px] rounded-full bg-gradient-to-t from-indigo-500/30 via-indigo-400/25 to-indigo-300/20 blur-[180px]" />
        <div className="absolute desktop:bottom-[-70%] laptop:bottom-[-70%] tablet:bottom-[-50%] mobile:bottom-[-50%] desktop:right-[-30%] laptop:right-[-30%] tablet:right-[-40%] mobile:right-[-40%] w-[1000px] h-[1000px] rounded-full bg-gradient-to-t from-indigo-400/25 via-indigo-300/20 to-indigo-200/15 blur-[200px]" />

        {/* Enhanced radial gradient overlay for depth */}
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,transparent_0%,rgba(99,102,241,0.1)_30%,rgba(99,102,241,0.05)_70%,transparent_100%)]" />
      </motion.div>
    </>
  );
};

export default PersonalGradientBackground;
