import { FC } from "react";
import { motion } from "framer-motion";
import ContactIllustration from "./components/ContactIllustration";
import SocialLinks from "./components/SocialLinks";
import EmailButton from "./components/EmailButton";

const Contact: FC = () => {
  return (
    <div id="contact">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.7, ease: [0.21, 0.45, 0.27, 0.9] }}
        className="grid desktop:grid-cols-2 laptop:grid-cols-2 tablet:grid-cols-1 mobile:grid-cols-1 gap-12"
      >
        <div className="flex flex-col justify-center space-y-8">
          {" "}
          <div className="space-y-2">
            <h2 className="text-4xl font-bold bg-gradient-to-r from-indigo-200 via-indigo-300 to-indigo-400 bg-clip-text text-transparent">
              Let's Create Something Amazing Together
            </h2>
          </div>
          <p className="text-zinc-400 text-lg leading-relaxed">
            Whether you have a project in mind or just want to chat about
            technology and innovation, I'm always excited to connect and explore
            new opportunities.
          </p>
          <div className="space-y-6">
            <EmailButton />
            <div className="space-y-3">
              <p className="text-zinc-500 text-sm font-medium">
                Connect with me on
              </p>
              <SocialLinks />
            </div>
          </div>
        </div>

        <div className="desktop:h-[500px] laptop:h-[500px] tablet:h-[400px] mobile:h-[300px]">
          <ContactIllustration />
        </div>
      </motion.div>
    </div>
  );
};

export default Contact;
