import { FC } from "react";
import { motion } from "framer-motion";

export const HaloEffects: FC = () => {
  return (
    <>
      <div className="absolute inset-0 pointer-events-none">
        <div className="absolute inset-[-50%] animate-halo">
          <div className="absolute inset-0 bg-gradient-to-r from-indigo-500/10 via-indigo-400/15 to-indigo-300/10 rounded-full blur-3xl" />
        </div>
        <div
          className="absolute inset-[-30%] animate-halo"
          style={{ animationDelay: "-5s" }}
        >
          <div className="absolute inset-0 bg-gradient-to-l from-indigo-400/10 via-indigo-300/15 to-indigo-200/10 rounded-full blur-2xl" />
        </div>
      </div>

      <div className="absolute inset-0 bg-gradient-to-r from-indigo-500/5 via-indigo-400/10 to-indigo-300/5" />

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: [0.1, 0.2, 0.1] }}
        transition={{ duration: 2, repeat: Infinity }}
        className="absolute inset-0 bg-indigo-400/5 blur-xl"
      />
    </>
  );
};
