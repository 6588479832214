import { motion } from "framer-motion";
import { FC } from "react";

export const GradientAccents: FC = () => (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 0.4 }}
    transition={{ duration: 2.5 }}
    className="absolute inset-0"
  >
    <div className="absolute desktop:top-[-30%] laptop:top-[-30%] tablet:top-[-20%] mobile:top-[-20%] desktop:right-[-20%] laptop:right-[-20%] tablet:right-[-30%] mobile:right-[-30%] w-[1000px] h-[1000px] rounded-full bg-gradient-to-b from-zinc-800/30 via-orange-500/20 to-transparent blur-[120px]" />
    <div className="absolute desktop:bottom-[-50%] laptop:bottom-[-50%] tablet:bottom-[-30%] mobile:bottom-[-30%] desktop:left-[-20%] laptop:left-[-20%] tablet:left-[-30%] mobile:left-[-30%] w-[800px] h-[800px] rounded-full bg-gradient-to-t from-zinc-800/30 via-orange-600/10 to-transparent blur-[120px]" />
  </motion.div>
);
