import { FC } from "react";

interface AttributeProps {
  icon: string;
  text: string;
}

export const Attribute: FC<AttributeProps> = ({ icon, text }) => (
  <div className="group relative">
    {/* Glow effect overlay */}
    <div className="absolute inset-0 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
      <div className="absolute inset-[-2px] bg-gradient-to-r from-orange-500/20 to-orange-400/20 rounded-full blur-xl" />
    </div>

    {/* Border glow */}
    <div className="absolute inset-[-1px] bg-gradient-to-r from-orange-500/30 to-orange-400/30 rounded-full opacity-0 group-hover:opacity-100 transition-opacity duration-300" />

    {/* Content */}
    <div className="relative flex items-center gap-2 bg-zinc-900/50 px-4 py-2 rounded-full border border-zinc-800/50 group-hover:border-orange-500/30 transition-colors duration-300">
      <span>{icon}</span>
      <span className="text-zinc-300 text-sm group-hover:text-zinc-100 transition-colors duration-300">
        {text}
      </span>
    </div>
  </div>
);
