import { useFrame } from "@react-three/fiber";
import { FC, useMemo, useRef } from "react";
import * as THREE from "three";

const Galaxy: FC = () => {
  const points = useRef<THREE.Points>(null);
  const particlesCount = 6000;
  const branches = 3;
  const radius = 10;
  const spin = 1;
  const randomness = 0.5;
  const randomnessPower = 3;
  const insideColor = new THREE.Color("#6366f1"); // Indigo color
  const middleColor = new THREE.Color("#f97316"); // Orange color
  const outsideColor = new THREE.Color("#3730a3"); // Darker indigo

  const positions = useMemo(() => {
    const positions = new Float32Array(particlesCount * 3);
    const colors = new Float32Array(particlesCount * 3);

    for (let i = 0; i < particlesCount; i++) {
      const i3 = i * 3;
      const radius_i = Math.random() * radius;
      const branchAngle = ((i % branches) / branches) * Math.PI * 2;
      const spinAngle = radius_i * spin;

      const randomX =
        Math.pow(Math.random(), randomnessPower) *
        (Math.random() < 0.5 ? 1 : -1) *
        randomness *
        radius_i;
      const randomY =
        Math.pow(Math.random(), randomnessPower) *
        (Math.random() < 0.5 ? 1 : -1) *
        randomness *
        radius_i;
      const randomZ =
        Math.pow(Math.random(), randomnessPower) *
        (Math.random() < 0.5 ? 1 : -1) *
        randomness *
        radius_i;

      positions[i3] = Math.cos(branchAngle + spinAngle) * radius_i + randomX;
      positions[i3 + 1] = randomY;
      positions[i3 + 2] =
        Math.sin(branchAngle + spinAngle) * radius_i + randomZ;

      // Create a gradient effect with three colors
      const mixedColor = insideColor.clone();
      if (radius_i < radius * 0.5) {
        mixedColor.lerp(middleColor, radius_i / (radius * 0.5));
      } else {
        mixedColor.lerp(
          outsideColor,
          (radius_i - radius * 0.5) / (radius * 0.5),
        );
      }

      colors[i3] = mixedColor.r;
      colors[i3 + 1] = mixedColor.g;
      colors[i3 + 2] = mixedColor.b;
    }

    return { positions, colors };
  }, []);

  useFrame((state) => {
    if (points.current) {
      points.current.rotation.y = state.clock.getElapsedTime() * 0.05;
    }
  });

  return (
    <points ref={points}>
      <bufferGeometry>
        <bufferAttribute
          attach="attributes-position"
          count={particlesCount}
          array={positions.positions}
          itemSize={3}
        />
        <bufferAttribute
          attach="attributes-color"
          count={particlesCount}
          array={positions.colors}
          itemSize={3}
        />
      </bufferGeometry>
      <pointsMaterial
        size={0.02}
        sizeAttenuation={true}
        depthWrite={false}
        vertexColors={true}
        blending={THREE.AdditiveBlending}
      />
    </points>
  );
};

export default Galaxy;
