import { FC, useRef } from "react";
import * as THREE from "three";
import { useParticlePositions } from "./hooks/useParticlePositions";
import { useParticleAnimation } from "./hooks/useParticleAnimation";
import { PARTICLE_COUNT } from "./constants";

const ContactSphere: FC = () => {
  const points = useRef<THREE.Points>(null);
  const { positions, originalPositions } = useParticlePositions();

  useParticleAnimation(points, originalPositions);

  return (
    <points ref={points}>
      <bufferGeometry>
        <bufferAttribute
          attach="attributes-position"
          count={PARTICLE_COUNT}
          array={positions}
          itemSize={3}
        />
      </bufferGeometry>
      <pointsMaterial
        size={0.02}
        color="#818cf8" // Indigo color
        transparent
        opacity={0.8}
        sizeAttenuation
        blending={THREE.AdditiveBlending}
      />
    </points>
  );
};

export default ContactSphere;
