import { FC } from "react";
import { motion } from "framer-motion";
import { FaGithub, FaLinkedin } from "react-icons/fa";

const SocialLinks: FC = () => {
  const links = [
    { icon: FaGithub, href: "https://github.com/hugosinp" },
    { icon: FaLinkedin, href: "https://www.linkedin.com/in/hugo-sinprasith" },
  ];

  return (
    <div className="flex gap-4">
      {links.map((link, index) => (
        <motion.a
          key={index}
          href={link.href}
          target="_blank"
          rel="noopener noreferrer"
          whileHover={{ scale: 1.1 }}
          className="p-3 bg-indigo-950/20 backdrop-blur-md border border-indigo-500/30 rounded-xl text-indigo-400 hover:text-indigo-300 hover:border-indigo-400/50 transition-all duration-300"
        >
          <link.icon className="desktop:w-6 desktop:h-6 laptop:w-6 laptop:h-6 tablet:w-5 tablet:h-5 mobile:w-5 mobile:h-5" />
        </motion.a>
      ))}
    </div>
  );
};

export default SocialLinks;
