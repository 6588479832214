import { FC } from "react";
import { useScrollToSection } from "./useScrollToSection";

export const ExploreButton: FC = () => {
  const scrollToSection = useScrollToSection();

  return (
    <button
      onClick={scrollToSection("stats")}
      className="group relative overflow-hidden desktop:px-10 laptop:px-10 tablet:px-8 mobile:px-8 desktop:py-5 laptop:py-5 tablet:py-4 mobile:py-4 bg-indigo-950/20 backdrop-blur-md text-indigo-100 rounded-xl font-medium transition-all duration-300 ease-out hover:scale-[1.02] border border-transparent hover:border-indigo-500/50 before:absolute before:inset-0 before:bg-gradient-to-r before:from-indigo-500/20 before:via-indigo-400/20 before:to-indigo-300/20 before:opacity-0 hover:before:opacity-100 before:transition-opacity before:duration-300 before:rounded-xl"
    >
      <span className="relative z-10 tracking-wide">Explore Work</span>
      <div className="absolute inset-0 bg-gradient-to-r from-indigo-400 to-indigo-300 transform translate-y-[101%] transition-transform duration-300 ease-out group-hover:translate-y-0" />
    </button>
  );
};
