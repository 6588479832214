import { FC } from "react";
import { motion } from "framer-motion";
import { FaAws } from "react-icons/fa";
import { Attribute } from "./Attribute";

export const IntroSection: FC = () => (
  <div className="flex flex-col justify-between h-full min-h-[400px] gap-8">
    <div className="space-y-8">
      <h2 className="text-4xl font-bold bg-gradient-to-r from-zinc-100 to-zinc-400 bg-clip-text text-transparent">
        Hello, I'm{" "}
        <span className="bg-gradient-to-r from-indigo-200 via-indigo-300 to-indigo-400 bg-clip-text text-transparent">
          Hugo !
        </span>
      </h2>
      <p className="text-zinc-400 text-lg leading-relaxed">
        A passionate full-stack developer with expertise in AWS cloud
        architecture and a drive for creating exceptional user experiences.
      </p>
      <p className="text-zinc-400 text-lg leading-relaxed">
        I specialize in building scalable, modern web applications that combine
        aesthetic appeal with technical excellence, leveraging both frontend and
        backend technologies.
      </p>
    </div>
    <div className="flex-grow flex flex-col space-y-8">
      <div className="flex-grow flex items-center">
        <span className="text-lg text-indigo-400/60 italic">
          "Simplicity is the ultimate sophistication."
        </span>
      </div>
      <div className="flex flex-wrap gap-4">
        <Attribute icon="💡" text="Problem Solver" />
        <Attribute icon="🎨" text="Creative Thinker" />
        <Attribute icon="🚀" text="Performance Focused" />
        <a
          href="https://www.credly.com/badges/037aa72c-4b84-4a7c-b44f-62b85d385ba6/public_url"
          target="_blank"
          rel="noopener noreferrer"
          className="group relative"
        >
          <motion.div whileHover={{ scale: 1.05 }} className="relative">
            {/* Glow effect overlay */}
            <div className="absolute inset-0 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
              <div className="absolute inset-[-2px] bg-gradient-to-r from-orange-500/20 to-orange-400/20 rounded-full blur-xl" />
            </div>

            {/* Border glow */}
            <div className="absolute inset-[-1px] bg-gradient-to-r from-orange-500/30 to-orange-400/30 rounded-full opacity-0 group-hover:opacity-100 transition-opacity duration-300" />

            {/* Content */}
            <div className="relative flex items-center gap-2 bg-[#232F3E] px-4 py-2 rounded-full border border-[#FF9900]/30 group-hover:border-orange-500/30 transition-colors duration-300">
              <FaAws className="text-[#FF9900] desktop:w-4 desktop:h-4 laptop:w-4 laptop:h-4 tablet:w-3 tablet:h-3 mobile:w-3 mobile:h-3" />
              <span className="text-[#FF9900] desktop:text-sm laptop:text-sm tablet:text-xs mobile:text-xs font-medium">
                AWS Certified
              </span>
            </div>
          </motion.div>
        </a>
      </div>
    </div>
  </div>
);
