import { FC } from "react";
import { motion } from "framer-motion";
import { Project } from "./types";
import { IconType } from "react-icons";

interface ProjectCardProps {
  project: Project;
}

const ProjectCard: FC<ProjectCardProps> = ({ project }) => {
  const handleClick = () => {
    if (project.title === "PrestaBook") {
      window.open("https://prestabook.me", "_blank");
    } else if (project.title === "Oro Archi") {
      window.open("https://www.oro.archi/", "_blank");
    } else if (project.title === "Oro Archi") {
      window.open("https://prestabook.me", "_blank");
    }
  };

  return (
    <motion.div
      whileHover={{ y: -8 }}
      transition={{ duration: 0.3 }}
      className="group relative bg-indigo-900/10 rounded-xl overflow-hidden border border-indigo-800/30 backdrop-blur-sm cursor-pointer"
      onClick={handleClick}
    >
      {/* Halo effect overlay */}
      <div className="absolute inset-0 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
        <div className="absolute inset-[-2px] bg-gradient-to-r from-indigo-500/20 via-indigo-400/20 to-indigo-300/20 rounded-xl blur-xl" />
      </div>

      {/* Glowing border */}
      <div className="absolute inset-[-1px] bg-gradient-to-r from-indigo-500 via-indigo-400 to-indigo-300 rounded-xl opacity-0 group-hover:opacity-100 animate-border-glow" />

      {/* Content container with relative positioning */}
      <div className="relative">
        <div className="aspect-[16/9] w-full overflow-hidden">
          <img
            src={project.image}
            alt={project.title}
            className="w-full h-full object-cover"
          />
        </div>
        <div className="p-6">
          <h3 className="text-xl font-semibold text-indigo-100 mb-2">
            {" "}
            {project.title}
            {project.subtitle && (
              <span className="text-sm text-indigo-400/80 block mt-1">
                {project.subtitle}
              </span>
            )}
          </h3>
          <p className="text-indigo-300 text-sm mb-4">{project.description}</p>
          <div className="flex flex-wrap gap-2">
            {project.technologies.map((tech) => {
              const Icon = tech.icon as IconType;
              return (
                <span
                  key={tech.name}
                  className="px-3 py-1 text-xs bg-indigo-800/30 text-indigo-300 rounded-full flex items-center gap-1.5"
                >
                  <Icon className="w-3.5 h-3.5" />
                  {tech.name}
                </span>
              );
            })}
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default ProjectCard;
