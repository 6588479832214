import { FC } from "react";
import { motion } from "framer-motion";
import { StatItemProps } from "./types";

export const StatItem: FC<StatItemProps> = ({ stat, index }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: index * 0.1, duration: 0.5 }}
      className="text-center relative group"
    >
      <motion.div
        className="text-3xl font-bold bg-gradient-to-r from-indigo-200 via-indigo-300 to-indigo-400 bg-clip-text text-transparent transition-transform duration-300 group-hover:scale-110"
        whileHover={{ scale: 1.05 }}
      >
        {stat.number}
      </motion.div>
      <div className="text-sm text-indigo-300 mt-2 font-medium">
        {stat.label}
      </div>
      <motion.div
        className="h-0.5 w-12 bg-gradient-to-r from-indigo-500/50 to-indigo-300/50 mx-auto mt-3"
        initial={{ width: 0 }}
        animate={{ width: 48 }}
        transition={{ delay: index * 0.1 + 0.3, duration: 0.5 }}
      />
    </motion.div>
  );
};
