import { Project } from "./types";
import {
  SiReact,
  SiNextdotjs,
  SiNestjs,
  SiTailwindcss,
  SiTypescript,
  SiMongodb,
  SiRedux,
  SiExpo,
} from "react-icons/si";

export const projects: Project[] = [
  {
    id: "1",
    title: "PrestaBook",
    description:
      "A simple yet complete booking management app for rising businesses",
    image:
      "https://createsiteai.b-cdn.net/c/_/c7b3d221-67cf-45f4-9360-ed558700c605",
    technologies: [
      { name: "React", icon: SiReact },
      { name: "Nextjs", icon: SiNextdotjs },
      { name: "NestJS", icon: SiNestjs },
      { name: "Tailwind", icon: SiTailwindcss },
    ],
    subtitle: "Booking Management Platform",
  },
  {
    id: "3",
    title: "Oro Archi",
    description:
      "Built a modern appealing website for a prestigious architecture agency",
    image:
      "https://createsiteai.b-cdn.net/c/_/12cb0932-4806-480b-bd46-4962658a90ee?unzoom=true",
    technologies: [
      { name: "React", icon: SiReact },
      { name: "TypeScript", icon: SiTypescript },
      { name: "Nextjs", icon: SiNextdotjs },
      { name: "TailwindCSS", icon: SiTailwindcss },
    ],
    subtitle: "Architecture Portfolio",
  },
  {
    id: "2",
    title: "WatchElse",
    description:
      "Intuitive mobile application for luxury watch reselling and watch enthousiasts meeting",
    image:
      "https://createsiteai.b-cdn.net/c/_/28a81704-ca88-4e73-a0ff-86f2917b63bf?unzoom=true",
    technologies: [
      { name: "React Native", icon: SiReact },
      { name: "Redux", icon: SiRedux },
      { name: "Expo", icon: SiExpo },
      { name: "MongoDB", icon: SiMongodb },
    ],
    subtitle: "Luxury Watch Marketplace",
  },
];
