import { FC } from "react";
import { motion } from "framer-motion";

interface SkillAreaProps {
  icon: string;
  title: string;
  description: string;
}

export const SkillArea: FC<SkillAreaProps> = ({ title, description, icon }) => (
  <motion.div
    whileHover={{ y: -4 }}
    transition={{ duration: 0.3 }}
    className="group relative bg-indigo-900/10 rounded-xl overflow-hidden border border-indigo-800/30 backdrop-blur-sm"
  >
    {/* Halo effect overlay */}
    <div className="absolute inset-0 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
      <div className="absolute inset-[-2px] bg-gradient-to-r from-indigo-500/20 via-indigo-400/20 to-indigo-300/20 rounded-xl blur-xl" />
    </div>

    {/* Glowing border */}
    <div className="absolute inset-[-1px] bg-gradient-to-r from-indigo-500 via-indigo-400 to-indigo-300 rounded-xl opacity-0 group-hover:opacity-100 animate-border-glow" />

    {/* Content container */}
    <div className="relative p-6">
      <div className="flex items-center gap-3 mb-3">
        <span className="text-2xl">{icon}</span>
        <h4 className="text-zinc-200 font-medium">{title}</h4>
      </div>
      <p className="text-zinc-400 text-sm leading-relaxed">{description}</p>
    </div>
  </motion.div>
);
