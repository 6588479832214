import { FC } from "react";
import { useScrollToSection } from "./useScrollToSection";

export const ContactButton: FC = () => {
  const scrollToSection = useScrollToSection();

  return (
    <button
      onClick={scrollToSection("contact")}
      className="group relative overflow-hidden desktop:px-10 laptop:px-10 tablet:px-8 mobile:px-8 desktop:py-5 laptop:py-5 tablet:py-4 mobile:py-4 bg-indigo-950/20 backdrop-blur-md border border-indigo-500/30 rounded-xl text-indigo-300 transition-all duration-300 hover:border-indigo-400/50 animate-pulse-subtle"
    >
      <div className="relative z-10 flex items-center justify-center">
        <span className="desktop:text-lg laptop:text-lg tablet:text-base mobile:text-base group-hover:text-zinc-200 tracking-wide">
          Get in Touch
        </span>
        <svg
          className="ml-3 w-5 h-5 transform transition-transform duration-300 group-hover:translate-x-1"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M17 8l4 4m0 0l-4 4m4-4H3"
          />
        </svg>
      </div>
      <div className="absolute inset-0 bg-zinc-800/30 transform scale-x-0 origin-left transition-transform duration-300 group-hover:scale-x-100" />
    </button>
  );
};
