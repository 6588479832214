import { useFrame } from "@react-three/fiber";
import { useSpring } from "framer-motion";
import { RefObject, useRef } from "react";
import * as THREE from "three";
import { words, PARTICLE_COUNT } from "../constants";

export const useParticleAnimation = (
  points: RefObject<THREE.Points>,
  originalPositions: Float32Array,
) => {
  const mouseX = useSpring(0);
  const mouseY = useSpring(0);
  const currentWord = useRef(0);
  const transitionProgress = useRef(0);

  useFrame((state) => {
    if (!points.current) return;

    // Update mouse spring values
    const { x, y } = state.mouse;
    mouseX.set(x * 0.1);
    mouseY.set(y * 0.1);

    // Rotate the entire point cloud
    points.current.rotation.x += 0.001;
    points.current.rotation.y += 0.002;

    // Update particles
    const positions = points.current.geometry.attributes.position
      .array as Float32Array;

    transitionProgress.current += 0.005;
    if (transitionProgress.current >= 1) {
      transitionProgress.current = 0;
      currentWord.current = (currentWord.current + 1) % words.length;
    }

    for (let i = 0; i < PARTICLE_COUNT; i++) {
      const i3 = i * 3;

      // Add wave effect
      const time = state.clock.getElapsedTime();
      const wave = Math.sin(time * 2 + originalPositions[i3] * 5) * 0.02;

      positions[i3] = originalPositions[i3] + wave + mouseX.get();
      positions[i3 + 1] = originalPositions[i3 + 1] + wave + mouseY.get();
      positions[i3 + 2] = originalPositions[i3 + 2] + wave;
    }

    points.current.geometry.attributes.position.needsUpdate = true;
  });
};
