import { FC, useEffect, useState } from "react";
import { motion, useScroll } from "framer-motion";
import { DeveloperTag } from "../Hero/components/DeveloperTag";
import Logo from "./components/Logo/Logo";

const Navbar: FC = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const [isScrolled, setIsScrolled] = useState(false);
  const { scrollY } = useScroll();

  useEffect(() => {
    const unsubscribe = scrollY.on("change", (latest) => {
      setIsScrolled(latest > 50);
    });
    return () => unsubscribe();
  }, [scrollY]);

  return (
    <motion.nav
      initial={{ y: -100 }}
      animate={{ y: 0 }}
      transition={{ duration: 0.5 }}
      className={`fixed top-0 left-0 right-0 z-50 transition-all duration-500 ${
        isScrolled
          ? "bg-zinc-900/80 shadow-lg shadow-zinc-950/5 border-b border-zinc-800/50"
          : "bg-transparent border-b border-transparent"
      } backdrop-blur-xl`}
    >
      <div className="desktop:max-w-7xl laptop:max-w-5xl tablet:max-w-3xl mobile:max-w-[95%] mx-auto desktop:px-4 laptop:px-4 tablet:px-3 mobile:px-3">
        <div className="relative flex items-center justify-between h-20 desktop:pl-4 laptop:pl-4 tablet:pl-3 mobile:pl-3">
          {" "}
          <div className="flex items-center">
            <motion.h1
              whileHover={{ scale: 1.05 }}
              transition={{ duration: 0.2 }}
              className="relative group cursor-pointer"
              onClick={scrollToTop}
            >
              <span className="text-2xl font-bold bg-gradient-to-r from-indigo-200 via-indigo-300 to-indigo-400 bg-clip-text text-transparent transition-colors duration-300">
                H.SINP
              </span>
              <span className="absolute bottom-0 left-0 w-full h-0.5 bg-gradient-to-r from-indigo-500/50 to-indigo-300/50 transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300" />
            </motion.h1>
          </div>
          <div className="relative">
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
              className="absolute -inset-x-4 -inset-y-2 bg-gradient-to-r from-indigo-500/5 via-indigo-400/5 to-indigo-300/5 blur-lg rounded-lg"
            />
            <DeveloperTag />
          </div>
        </div>
      </div>
    </motion.nav>
  );
};

export default Navbar;
