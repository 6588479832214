import { motion } from "framer-motion";
import { FC } from "react";

interface MainHeadingProps {
  variants: any;
}

export const MainHeading: FC<MainHeadingProps> = ({ variants }) => (
  <motion.div
    custom={0.3}
    variants={variants}
    initial="hidden"
    animate="visible"
    className="mt-10"
  >
    <h1 className="desktop:text-[8.5rem] laptop:text-[7rem] tablet:text-[5rem] mobile:text-[3.5rem] font-bold tracking-tight leading-[0.9] font-['Avenir Next']">
      <span className="bg-gradient-to-r from-indigo-200 via-indigo-300 to-indigo-400 bg-clip-text text-transparent">
        Creative
      </span>
      <br />
      <span className="bg-gradient-to-r from-indigo-400 to-indigo-500 bg-clip-text text-transparent">
        Developer
      </span>
    </h1>
  </motion.div>
);
