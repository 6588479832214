import { FC } from "react";
import { motion } from "framer-motion";
import ProjectCard from "./ProjectCard";
import { projects } from "./data";

const ProjectGrid: FC = () => {
  return (
    <div className="grid desktop:grid-cols-3 laptop:grid-cols-2 tablet:grid-cols-2 mobile:grid-cols-1 gap-8">
      {projects.map((project, index) => (
        <motion.div
          key={project.id}
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{
            duration: 0.7,
            delay: index * 0.1,
            ease: [0.21, 0.45, 0.27, 0.9],
          }}
        >
          <ProjectCard project={project} />
        </motion.div>
      ))}
    </div>
  );
};

export default ProjectGrid;
