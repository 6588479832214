import { FC } from "react";
import { StatItem } from "./StatItem";
import { statsData } from "./statsData";

export const StatsGrid: FC = () => {
  return (
    <div className="relative desktop:max-w-6xl laptop:max-w-4xl tablet:max-w-2xl mobile:max-w-sm mx-auto desktop:px-8 laptop:px-8 tablet:px-6 mobile:px-2 py-8">
      <div className="grid desktop:grid-cols-3 laptop:grid-cols-3 tablet:grid-cols-3 mobile:grid-cols-3 gap-8">
        {statsData.map((stat, index) => (
          <StatItem key={index} stat={stat} index={index} />
        ))}
      </div>
    </div>
  );
};
