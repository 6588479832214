import { motion } from "framer-motion";
import { FC } from "react";

interface DeveloperTagProps {
  variants?: any;
}

export const DeveloperTag: FC<DeveloperTagProps> = () => {
  const scrollToContact = (e: React.MouseEvent) => {
    e.preventDefault();
    const contactSection = document.getElementById("contact");
    if (contactSection) {
      const yOffset = -80; // Offset to account for fixed header
      const y =
        contactSection.getBoundingClientRect().top +
        window.pageYOffset +
        yOffset;

      window.scrollTo({
        top: y,
        behavior: "smooth",
      });
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="inline-flex items-center"
    >
      <button
        onClick={scrollToContact}
        className="group flex items-center gap-2.5 bg-zinc-900/80 backdrop-blur-xl border border-zinc-800/50 rounded-full desktop:px-5 laptop:px-5 tablet:px-4 mobile:px-4 desktop:py-2.5 laptop:py-2.5 tablet:py-2 mobile:py-2 transition-all duration-300 hover:border-zinc-700/50"
      >
        <span className="relative flex h-2.5 w-2.5">
          <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-emerald-400 opacity-75"></span>
          <span className="relative inline-flex rounded-full h-2.5 w-2.5 bg-emerald-500"></span>
        </span>
        <span className="font-mono text-zinc-300 desktop:text-sm laptop:text-sm tablet:text-xs mobile:text-xs tracking-wide">
          OPEN TO OPPORTUNITIES
        </span>
      </button>
    </motion.div>
  );
};
