import { FC } from "react";
import { motion } from "framer-motion";
import PersonalPresentation from "../PersonalPresentation/PersonalPresentation";
import Contact from "../Contact/Contact";
import PersonalGradientBackground from "../PersonalPresentation/PersonalPresentation/components/PersonalGradientBackground";

const MainContent: FC = () => {
  return (
    <section className="relative w-full bg-gradient-to-b from-[#0A0A0B] via-[#080819] to-[#0A0A0B] text-zinc-50 overflow-hidden desktop:py-48 laptop:py-48 tablet:py-36 mobile:py-24 desktop:pb-24 laptop:pb-24 tablet:pb-16 mobile:pb-12">
      <div className="absolute inset-0 bg-gradient-to-b from-indigo-500/10 via-indigo-400/5 to-indigo-300/10 bg-grid-pattern pointer-events-none" />
      <div className="relative z-20 desktop:max-w-5xl laptop:max-w-4xl tablet:max-w-3xl mobile:max-w-[90%] mx-auto desktop:px-12 laptop:px-10 tablet:px-8 mobile:px-3">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.7, ease: [0.21, 0.45, 0.27, 0.9] }}
          className="desktop:space-y-48 laptop:space-y-48 tablet:space-y-48 mobile:space-y-16"
        >
          <div className="desktop:space-y-48 laptop:space-y-48 tablet:space-y-48 mobile:space-y-16">
            <PersonalPresentation />
          </div>
          <Contact />
        </motion.div>
      </div>
      <PersonalGradientBackground />
    </section>
  );
};

export default MainContent;
