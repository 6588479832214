export const useFadeUpAnimation = () => ({
  hidden: { opacity: 0, y: 20 },
  visible: (delay: number) => ({
    opacity: 1,
    y: 0,
    transition: {
      delay,
      duration: 0.7,
      ease: [0.21, 0.45, 0.27, 0.9],
    },
  }),
});
