import { FC } from "react";
import { IntroSection } from "./IntroSection";
import { SkillsSection } from "./SkillsSection";
import ProjectGrid from "../../Projects/ProjectGrid";

const PersonalPresentation: FC = () => {
  return (
    <div
      id="presentation"
      className="desktop:space-y-48 laptop:space-y-48 tablet:space-y-48 mobile:space-y-16"
    >
      <div className="grid desktop:grid-cols-2 laptop:grid-cols-2 tablet:grid-cols-1 mobile:grid-cols-1 gap-12">
        <IntroSection />
        <SkillsSection />
      </div>
      <div>
        <div className="desktop:space-y-2 laptop:space-y-2 tablet:space-y-2 mobile:space-y-1 desktop:mb-12 laptop:mb-12 tablet:mb-12 mobile:mb-6">
          <h2 className="text-4xl font-bold bg-gradient-to-r from-zinc-100 to-zinc-400 bg-clip-text text-transparent">
            Featured Projects
          </h2>
          <p className="text-indigo-400/80 desktop:text-lg laptop:text-lg tablet:text-lg mobile:text-base">
            A showcase of my recent work and technical expertise
          </p>
        </div>
        <ProjectGrid />
      </div>
    </div>
  );
};

export default PersonalPresentation;
