import { FC } from "react";
import { MainHeading } from "./components/MainHeading";
import { Description } from "./components/Description";
import { CTAButtons } from "./components/CTAButtons";
import { useFadeUpAnimation } from "./hooks/useFadeUpAnimation";
import GalaxyBackground from "./components/Galaxy/GalaxyBackground";
import { GradientAccents } from "./components/GradientAccents";

const HeroSection: FC = () => {
  const fadeUpVariants = useFadeUpAnimation();

  return (
    <section className="relative min-h-screen w-full bg-[#0A0A0B] text-zinc-50 overflow-hidden">
      <GalaxyBackground />
      <GradientAccents />

      {/* Main Content */}
      <div className="relative z-20 desktop:max-w-7xl laptop:max-w-5xl tablet:max-w-3xl mobile:max-w-[95%] mx-auto h-screen desktop:px-4 laptop:px-4 tablet:px-3 mobile:px-3">
        <div className="flex flex-col justify-center h-full desktop:pt-16 laptop:pt-14 tablet:pt-12 mobile:pt-24">
          <MainHeading variants={fadeUpVariants} />
          <Description variants={fadeUpVariants} />
          <CTAButtons variants={fadeUpVariants} />
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
