import { motion } from "framer-motion";
import { FC } from "react";

interface DescriptionProps {
  variants: any;
}

export const Description: FC<DescriptionProps> = ({ variants }) => (
  <motion.p
    custom={0.4}
    variants={variants}
    initial="hidden"
    animate="visible"
    className="desktop:text-xl laptop:text-xl tablet:text-lg mobile:text-base mobile:font-bold desktop:font-bold laptop:font-bold tablet:font-bold text-zinc-400 mt-10 desktop:max-w-2xl laptop:max-w-xl tablet:max-w-lg mobile:max-w-sm leading-relaxed"
  >
    Building digital experiences at the intersection of design and technology.
    Focused on creating performant, accessible, and visually refined
    applications.
  </motion.p>
);
